<template>
  <div>
    <div class="header-page">
      <h4>{{ $route.query.name }}</h4>
      <div class="mt-1" id="inventory-summery">
        Quantity : {{ quantity | numeral("0,0") }} , Total Price :
        {{ total_price | numeral("0,0") }}
      </div>
      <!-- <div class="badge-inventory">ไม่รวมจำนวนติดลบ</div> -->
    </div>

    <b-tabs class="mt-4" v-model="tabsIndex">
      <b-tab title="Inventory" active>
        <div class="bg-white p-2 p-lg-3">
          <b-input-group class="mb-2">
            <b-form-input
              type="text"
              id="header-search-bar"
              class="search-bar"
              @keyup.enter="handleSearch"
              placeholder="Search Name, Barcode "
              v-model="filter.search"
            >
            </b-form-input>
            <b-input-group-append is-text class="mr-2">
              <b-iconstack font-scale="2" type="submit" @click="handleSearch">
                <b-icon
                  stacked
                  icon="search"
                  scale="0.5"
                  variant="grey"
                ></b-icon>
              </b-iconstack>
            </b-input-group-append>
            <b-button @click.prevent="sidebarFilter" class="btn-filter mr-2">
              <font-awesome-icon icon="filter" class="d-md-none" />
              <span class="d-none d-md-block">
                <font-awesome-icon icon="filter" class="pointer" />
                <span class="btn-text">Filter</span>
              </span>
            </b-button>
            <b-dropdown
              id="dropdown"
              variant="filter"
              right
              class="btn-mobile mr-2"
            >
              <template #button-content>
                <span class="d-none d-md-inline-flex btn-text mr-1"
                  >Action</span
                >
              </template>
              <b-dropdown-item @click="modalShow = !modalShow">
                <span>Import Product </span>
              </b-dropdown-item>
              <b-dropdown-item @click="exportFile()">
                <span>Export (.xlsx) </span>
              </b-dropdown-item>
              <b-dropdown-item @click="downloadTemplate()">
                <span>Download Template </span>
              </b-dropdown-item>
            </b-dropdown>
          </b-input-group>
          <b-modal v-model="modalShow" hide-footer hide-header>
            <b-row class="no-gutters mt-3">
              <b-col md="12" class="justify-content-start">
                <UploadFileV2
                  textFloat="File"
                  placeholder="Please Choose File"
                  format="excel"
                  name="file"
                  text="*Please upload only file .xlsx less than 10 MB"
                  isRequired
                  v-on:onFileChange="onFileChange"
                  v-on:delete="deleteFile"
                  :fileName="filename"
                  id="uploadfile"
                  v-model="form.excel_file"
                />
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-button size="md" @click="clearModal()" class="btn-cancel"
                  >Cancel</b-button
                >
              </b-col>
              <b-col class="text-right">
                <b-button
                  id="upload"
                  class="main-color btn-save color-btn"
                  size="md"
                  :disabled="isDisable"
                  @click="importData"
                  >Upload</b-button
                >
              </b-col>
            </b-row>
          </b-modal>
          <b-row>
            <b-col>
              <b-table
                responsive
                hover
                :fields="fields"
                :items="items"
                :busy="isBusy"
                show-empty
                empty-text="No matching records found"
              >
                <template v-slot:cell(image_url)="{ item }">
                  <b-img
                    v-if="item.image_url"
                    class="box-image1-1 img-fluid"
                    :src="item.image_url"
                    @error="handleImageSrc"
                  />
                  <b-img
                    v-else
                    class="box-image1-1 img-fluid"
                    :src="require('@/assets/img/default-placeholder.png')"
                    @error="handleImageSrc"
                  />
                </template>
                <template v-slot:cell(name)="{ item }">
                  <div>{{ item.name }}</div>
                  <div>
                    <span class="text-secondary mr-2">{{ item.code }}</span>
                    <span class="badge badge-secondary">{{
                      item.product_type
                    }}</span>
                  </div>
                </template>
                <template v-slot:cell(price)="{ item }">
                  <span>{{ item.price | numeral("0,.00") }}</span>
                </template>
                <template v-slot:cell(stock)="{ item }">
                  <span>{{ item.stock | numeral("0,0") }}</span>
                </template>
                <template v-slot:cell(actions)="{ item }">
                  <div class="d-flex justify-content-center">
                    <b-button
                      variant="link"
                      class="text-bg-primary px-1 py-0"
                      @click="openModal(item)"
                    >
                      <font-awesome-icon
                        style="transform: scale(1.2)"
                        icon="sliders-h"
                        title="Inventory"
                      />
                    </b-button>
                  </div>
                </template>

                <template v-slot:table-busy>
                  <div class="text-center text-black my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong class="ml-2">Loading...</strong>
                  </div>
                </template>
              </b-table>
            </b-col>
          </b-row>
          <Pagination
            @handleChangeTake="handleChangeTake"
            :filter="filter"
            :rows="rows"
            @pagination="pagination"
          />
        </div>
      </b-tab>
      <b-tab title="Inventory Log">
        <div class="bg-white p-2 p-lg-3">
          <b-input-group class="mb-2">
            <b-form-input
              type="text"
              id="header-search-bar"
              class="search-bar"
              @keyup.enter="handleSearch"
              placeholder="Search Name, Barcode "
              v-model="filterLog.search"
            >
            </b-form-input>
            <b-input-group-append is-text class="mr-2">
              <b-iconstack font-scale="2" type="submit" @click="handleSearch">
                <b-icon
                  stacked
                  icon="search"
                  scale="0.5"
                  variant="grey"
                ></b-icon>
              </b-iconstack>
            </b-input-group-append>
            <b-button @click.prevent="sidebarFilter" class="btn-filter mr-2">
              <font-awesome-icon icon="filter" class="d-md-none" />
              <span class="d-none d-md-block">
                <font-awesome-icon icon="filter" class="pointer" />
                <span class="btn-text">Filter</span>
              </span>
            </b-button>
          </b-input-group>

          <Table
            class="inventory-table"
            :filter="filterLog"
            :fields="fieldsLog"
            :items="itemsLog"
            :isBusy="isBusy"
            :rows="rowsLog"
            :showingTo="showingTo"
            @filterPage="filterPage"
          />
        </div>
      </b-tab>
    </b-tabs>
    <ModalEditStock ref="modal-edit-stock" @getData="getInventory" />
    <SideBarFilter
      ref="sidebarFilter"
      :filter="tabsIndex == 0 ? filter : filterLog"
      :hideStatusFilter="false"
      placeholder="Search Name, Barcode (SKU)"
      @searchAll="handleOnFilter"
      @clearFilter="clearFilter"
    >
      <template v-slot:filter-option>
        <div v-if="tabsIndex == 0">
          <div class="mb-3">
            <label for="price-min"> Price </label>
            <div class="filter-input">
              <InputText
                textFloat=""
                placeholder="Min"
                type="number"
                name="price-min"
                class="mb-0"
                v-model="filter.price_min"
              />
              <div>-</div>
              <InputText
                textFloat=""
                placeholder="Max"
                type="number"
                class="mb-0"
                name="price-max"
                v-model="filter.price_max"
              />
            </div>
          </div>
          <div class="mb-3">
            <label for="available-min"> Available </label>
            <div class="filter-input">
              <InputText
                textFloat=""
                placeholder="Min"
                type="number"
                name="available-min"
                useSub
                class="mb-0"
                v-model="filter.available_min"
              />
              <div>-</div>
              <InputText
                textFloat=""
                placeholder="Max"
                type="number"
                class="mb-0"
                useSub
                name="available-max"
                v-model="filter.available_max"
              />
            </div>
          </div>
          <div>
            <InputDropdownCheckbox
              textFloat="Product Type"
              name="product-type"
              :valueSelectedAll="productSelect"
              :value="filter.product_type"
              :options="productType"
              @onDataChangeCheckAll="(val) => (productSelect = val)"
              @onDataChange="(val) => (filter.product_type = val)"
            />
          </div>
        </div>
        <div v-else>
          <InputDropdownCheckbox
            textFloat="Product Type"
            name="product-type"
            :valueSelectedAll="activitySelect"
            :value="filterLog.log_type"
            :options="activityOptions"
            @onDataChangeCheckAll="(val) => (activitySelect = val)"
            @onDataChange="(val) => (filterLog.log_type = val)"
          />
        </div>
      </template>
    </SideBarFilter>
  </div>
</template>

<script>
import InputDropdownCheckbox from "@/components/inputs/InputDropdownCheckbox";
import HeaderPanel from "@/components/HeaderPanel";
import SideBarFilter from "@/components/SideBarFilter";
import UploadFileV2 from "@/components/inputs/UploadFileV2";
import Table from "./Table";
import ModalEditStock from "./components/ModalEditStock.vue";
export default {
  components: {
    HeaderPanel,
    SideBarFilter,
    Table,
    UploadFileV2,
    ModalEditStock,
    InputDropdownCheckbox,
  },
  data() {
    return {
      branchSelectAll: false,
      branchSelected: [],
      branchSelectOption: [],
      typeSelectAll: false,
      typeSelected: [],
      productSelect: true,
      typeSelectOption: [],

      filter: {
        search: "",
        page: 1,
        price_min: "",
        price_max: "",
        available_min: "",
        available_max: "",
        take: 10,
        branch_id: Number(this.$route.params.id),
        product_type: ["ZFIN", "ZLPR", "ZPACK", "ZSAM", "ZSER"],
      },
      filterLog: {
        search: "",
        page: 1,
        take: 10,
        log_type: [],
        branch_id: Number(this.$route.params.id),
      },
      isBusy: false,
      rows: 0,
      quantity: 0,
      total_price: 0,
      rowsLog: 0,
      showing: 1,
      showingTo: 10,
      tabsIndex: 0,
      productType: [
        { text: "ZFIN", value: "ZFIN" },
        { text: "ZLPR", value: "ZLPR" },
        { text: "ZPACK", value: "ZPACK" },
        { text: "ZSAM", value: "ZSAM" },
        { text: "ZSER", value: "ZSER" },
      ],
      fields: [
        {
          key: "image_url",
          label: "Image",
          thClass: "upper",
        },
        {
          key: "name",
          label: "Name / Code / Type",
          tdClass: "text-left",
        },

        {
          key: "barcode",
          label: "Barcode",
        },

        {
          key: "price",
          label: "Price",
        },
        {
          key: "stock",
          label: "Available",
        },

        {
          key: "actions",
          label: "",
        },
      ],
      fieldsLog: [
        {
          key: "name",
          label: "Name / Barcode (SKU)",
          tdClass: "text-left p-3",
        },
        {
          key: "log_type_name",
          label: "Type",
        },

        {
          key: "stock",
          label: "Quantity",
        },
        {
          key: "available",
          label: "Available",
        },

        {
          key: "note",
          label: "Note",
        },
        {
          key: "create_date",
          label: "Date",
        },
      ],
      itemsLog: [],
      items: [],
      activityOptions: [],
      activitySelect: false,
      modalShow: false,
      filename: "",
      form: {
        excel_file: "",
        branch_id: this.$route.params.id,
      },
      isDisable: false,
    };
  },
  mounted() {
    this.getInventory();
    // this.getInventoryLog();
    this.getInventoryActivity();
  },
  watch: {
    tabsIndex(val) {
      if (val) {
        this.getInventoryLog();
      } else {
        this.getInventory();
      }
      return (this.filter.search = ""), (this.filterLog.search = "");
    },
  },
  methods: {
    handleOnFilter() {
      if (this.tabsIndex == 0) this.getInventory();
      else this.getInventoryLog();
    },
    async getInventoryActivity() {
      const response = await this.axios(`/Inventory/GetInventoryActivity`);

      this.activityOptions = response.data.detail.map((el) => {
        return { text: el.name, value: Number(el.id) };
      });
    },
    async getInventoryLog() {
      this.isBusy = true;
      const response = await this.axios.post(
        `/Inventory/GetInventorylog`,
        this.filterLog
      );

      this.itemsLog = response.data.detail.data;
      this.rowsLog = response.data.detail.count;
      this.isBusy = false;
    },
    async getInventory() {
      this.isBusy = true;
      let payload = { ...this.filter };
      payload.price_min = payload.price_min == "" ? null : payload.price_min;
      payload.price_max = payload.price_max == "" ? null : payload.price_max;
      payload.available_min =
        payload.available_min == "" ? null : payload.available_min;
      payload.available_max =
        payload.available_max == "" ? null : payload.available_max;
      const response = await this.axios.post(
        `/Inventory/GetInventory`,
        payload
      );

      this.items = response.data.detail.data;
      this.rows = response.data.detail.count;
      this.quantity = response.data.detail.quantity;
      this.total_price = response.data.detail.total_price;
      this.isBusy = false; // api
    },
    autoSearch() {
      if (this.tabsIndex) {
        this.getInventoryLog();
      } else {
        this.getInventory();
      }
    },
    handleSearch() {
      if (this.tabsIndex) {
        this.getInventoryLog();
      } else {
        this.getInventory();
      }
    },
    clearModal() {
      this.modalShow = false;
      this.form.excel_file = "";
      this.filename = "";
    },
    getStockList() {},
    clearFilter() {
      this.productSelect = true;
      this.filter = {
        search: "",
        page: 1,
        price_min: "",
        price_max: "",
        available_min: "",
        available_max: "",
        take: 10,
        branch_id: Number(this.$route.params.id),
        product_type: ["ZFIN", "ZLPR", "ZPACK", "ZSAM", "ZSER"],
      };
      this.filterLog = {
        search: "",
        page: 1,
        take: 10,
        log_type: [],
        branch_id: Number(this.$route.params.id),
      };
    },
    sidebarFilter() {
      this.$refs.sidebarFilter.show();
    },
    filterPage() {
      this.getInventoryLog();
    },
    handleChangeTake(val) {
      this.filter.page = 1;
      this.filter.take = val;
      this.getInventory();
    },
    pagination(val) {
      this.filter.page = val;

      this.getInventory();
    },
    openModal(items) {
      this.$refs["modal-edit-stock"].show(items);
    },
    async importData() {
      this.$bus.$emit("showLoading");

      let data = await this.axios.post(`/Inventory/ImportInventory`, this.form);

      if (data.data.detail) {
        this.getInventory();
        this.getInventoryLog();
        this.successAlert();
        this.clearModal();
      } else {
        this.errorAlert("Export Fails");
      }

      this.$bus.$emit("hideLoading");
    },
    async exportFile() {
      this.$bus.$emit("showLoading");

      let data = await this.axios(
        `/Inventory/ExportInventory/${this.$route.params.id}`,
        {
          responseType: "blob",
        }
      );
      if (data.status == 200) {
        var fileURL = window.URL.createObjectURL(new Blob([data.data]));
        var fileLink = document.createElement("a");
        var dateExcel = this.$moment().format("DD-MM-YYYY");

        fileLink.href = fileURL;
        fileLink.setAttribute(
          "download",
          `Inventory-${this.$route.query.name}-${dateExcel}.xlsx`
        );
        document.body.appendChild(fileLink);
        fileLink.click();
        this.exportBookingName = "";
      } else {
        this.errorAlert("Export Fails");
      }

      this.$bus.$emit("hideLoading");
    },
    async downloadTemplate() {
      this.$bus.$emit("showLoading");

      let data = await this.axios(`/Inventory/GetInventoryTemplate`, {
        responseType: "blob",
      });
      if (data.status == 200) {
        var fileURL = window.URL.createObjectURL(new Blob([data.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", `Inventory-Import-template.xlsx`);
        document.body.appendChild(fileLink);
        fileLink.click();
      } else {
        this.errorAlert("Export Fails");
      }

      this.$bus.$emit("hideLoading");
    },
    deleteFile(value) {
      this.form.excel_file = null;
      this.filename = null;
      this.isDisable = true;
    },
    onFileChange(file) {
      this.filename = file.name;
      this.form.file_name = file.name;
      this.isDisable = false;
      const str =
        "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.form.excel_file = reader.result.substring(
          str.length,
          reader.result.length
        );
      };
    },
  },
};
</script>

<style lang="scss" scoped>
#inventory-summery {
  position: relative;
  font-weight: normal;
  max-width: fit-content;
  &::after {
    position: absolute;
    border-radius: 5px;
    padding: 0px 5px;
    background: red;
    color: white;
    content: "ไม่รวมจำนวนติดลบ";
    bottom: -75%;
    white-space: nowrap;
    left: 0;
    font-size: 10px;
  }
}
::v-deep .width-btn {
  min-width: 7rem;
}
::v-deep .dropdown-toggle.btn-secondary {
  background-color: #fff;
  color: var(--primary-color);
  border-color: var(--primary-color);
}
::v-deep .dropdown-item:active {
  text-decoration: none;
  background-color: #fff;
  color: var(--primary-color) !important;
}
::v-deep .dropdown-menu {
  width: fit-content;
  li:hover {
    text-decoration: none;
    background-color: #fff;
    color: var(--primary-color) !important;
  }
}
::v-deep .btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  background-color: transparent;
  border-color: transparent;
}
::v-deep .filter-input {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  gap: 1rem;
}

.header-page {
  color: black;
  font-weight: bold;
  h4 {
    font-size: 25px;
    // &::after {
    // }
  }
}
::v-deep .dropdown-menu li:hover {
  color: #000000 !important;
}
::v-deep .inventory-table table th {
  width: 16.67%;
}
</style>
